import axios from 'axios'
import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { getProjectRoutes } from "../api/projectApi"

/* Action */
const INIT = 'INIT'
const UPDATE_STATE = 'UPDATE_STATE'
const SET_SHOW_MAP = 'SET_SHOW_MAP'
const SET_PORTFOLIO = 'SET_PORTFOLIO'
const SET_GIS_PDF_TEMPLATES = 'SET_GIS_PDF_TEMPLATES'
const SET_ROUTES = 'SET_ROUTES'

/* Initial State */
const initialState = {
  project: {},
  portfolio: null,
  portfolioType: '',
  showMap: true,
  gisPdfTemplates: [],
}

/* Reducer */

const projectReducer = (state, action) => {
  switch (action?.type) {
    case INIT:
      return {
        ...state,
        routes: action.payload.routes,
        project: action.payload.project,
      }
    case UPDATE_STATE:
      return { ...state, ...action.payload }
    case SET_PORTFOLIO:
      return { ...state, portfolio: action.payload.portfolio, portfolioType: action.payload.portfolioType }
    case SET_GIS_PDF_TEMPLATES:
      return { ...state, gisPdfTemplates: action.payload }
    case SET_ROUTES:
      return { ...state, routes: action.payload }
    default:
      return state
  }
}

/* Contexts */
const ProjectContext = createContext(initialState)

const ProjectApiContext = createContext({
  setShowMap: () => {},
  setPortfolio: () => {},
  getGisPdfTemplates: () => {},
})

/* Provider */
export const ProjectProvider = ({ children, project, routes }) => {
  const { projectId } = useParams()
  const [state, dispatch] = useReducer(projectReducer, initialState)

  const api = useMemo(() => {

    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, payload: { [field]: value } })

    const setShowMap = (boolean) => dispatch({ type: SET_SHOW_MAP, payload: boolean })

    const setPortfolio = (portfolio, portfolioType) =>
      dispatch({ type: SET_PORTFOLIO, payload: { portfolio: portfolio, portfolioType: portfolioType } })

    const getGisPdfTemplates = (gisPdfTemplatesPath) => axios.get(gisPdfTemplatesPath)

    const setGisPdfTemplates = (GisPdfTemplates) =>
      dispatch({ type: SET_GIS_PDF_TEMPLATES, payload: GisPdfTemplates })

    return {
      setShowMap, setPortfolio, getGisPdfTemplates, setGisPdfTemplates, updateState
    }
  }, [])

  const internalApi = useMemo(() => {

    const loadGisPdfTemplates = (routes) => {
      api.getGisPdfTemplates(routes.gisPdfsPath)
        .then((response) => { api.setGisPdfTemplates(response.data) })
    }

    const updateProjectRoutes = () => {
      getProjectRoutes(projectId)
        .then((response) => dispatch({ type: SET_ROUTES, payload: response.data }))
    }

    return { loadGisPdfTemplates, updateProjectRoutes }
  }, [])


  useEffect(() => {
    if (project?.portfolio) {
      api.setPortfolio(project?.portfolio, project?.portfolio?.type)
    }

    internalApi.loadGisPdfTemplates(routes)
    dispatch({
      type: INIT,
      payload: {
        project: project,
      }
    })
  }, [])

  return (
    <ProjectApiContext.Provider value={ api }>
      <ProjectContext.Provider value={ { ...state, routes: routes } }>
        { children }
      </ProjectContext.Provider>
    </ProjectApiContext.Provider>
  )
}

/* Custom Context Hooks */
export const useProjectContext = () => useContext(ProjectContext)
export const useProjectApiContext = () => useContext(ProjectApiContext)
