import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getBoreholeId } from "../../utilities/mobileRecordHelper"


const MobileRecordRow = ({ mobileRecord }) => {
  const [isSelected, setIsSelected] = useState(false)
  const { noteId, projectId } = useParams()
  const boreholeName = (mobileRecord.record_type === 'Borehole') ? getBoreholeId(mobileRecord) : mobileRecord.record_type
  const mobileRecordRoute = `/projects/${projectId}/notes/${mobileRecord.id}`
  useEffect(() => {
    setIsSelected(noteId && mobileRecord.id === parseInt(noteId))
  }, [noteId])

  return (
    <div className='divide-x divide-gray-200 w-32'>
      <div
        className={`bg-gray-50 px-6 py-3 text-left text-sm font-medium uppercase tracking-wider flex flex-row justify-between items-center ${isSelected ? 'bg-gray-100' : 'bg-white'}`}>
        <Link to={mobileRecordRoute} className='flex flex-row gap-x-2 items-center'>
          <FontAwesomeIcon className='cursor-pointer w-8' icon={isSelected ? faChevronDown : faChevronRight} />
          {mobileRecord.record_type} {boreholeName}
        </Link>
      </div>
    </div>
  )
}

export default MobileRecordRow
