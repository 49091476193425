export const getBoreholeId = (mobileRecord) => {
  const boreholeEntry = mobileRecord.entries.find(entry => entry.label === 'Borehole ID')
  const boreholeId = boreholeEntry ? boreholeEntry.value : undefined
  
  return boreholeId
}

export const updateEntryHelper = (mobileRecord, entry) => {
  const updatedEntries = mobileRecord.entries.map(e => e.id === entry.id ? entry : e)
  return { ...mobileRecord, entries: updatedEntries }
}
