import { isEmpty } from "lodash"
import React from 'react'
import { useMobileRecordsContext } from '../../contexts/mobileRecordsContext'
import { useProjectContext } from "../../contexts/projectContext"
import LoadingSpinner from '../shared/loadingSpinner'
import Onboarding from '../shared/onboarding'
import Layout from "./layout"
import MobileRecordRow from "./MobileRecordRow"

const RenderMobileRecords = ({ mobileRecords }) => {
  return (
    !isEmpty(mobileRecords) && (
      <div className="flex overflow-x-scroll flex-row min-h-10">
        {mobileRecords.map(mobileRecord =>
          mobileRecord.ref_parent_guid === null ? (
            <MobileRecordRow 
              key={mobileRecord.id} 
              mobileRecord={mobileRecord} 
            />
          ) : null
        )}
      </div>
    )
  )
}

const MobileRecords = ({ children }) => {
  const { mobileRecords, loading } = useMobileRecordsContext()
  const { project, portfolio, routes, showMap } = useProjectContext()
  return (
    <div className='flex flex-col flex-1 h-full'>
      {loading && <LoadingSpinner />}
      {!loading && !isEmpty(mobileRecords) && (
        <Layout project={project} portfolio={portfolio} routes={routes} showMap={showMap}  >
        <RenderMobileRecords mobileRecords={mobileRecords} />
          {children}
        </Layout>
      )}
      {!loading && isEmpty(mobileRecords) && (
        <div className='flex flex-col lg:flex-row lg:gap-x-2 flex-grow overflow-hidden'>
          <div className='flex flex-col w-full h-2/3 lg:h-auto lg:w-1/2 flex-grow overflow-scroll'>
            <Onboarding title='No notes found.' />
          </div>
        </div>
      )}
    </div>
  )
}

export default MobileRecords
